.markdown-editor h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

.markdown-editor h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
}

.markdown-editor h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.markdown-editor h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
}

.markdown-editor h5 {
    font-size: 0.875rem;
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.markdown-editor h6 {
    font-size: 0.75rem;
    font-weight: bold;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
}

.markdown-editor p {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

/* Lists */
.markdown-editor ul {
    list-style-type: disc;
    padding-left: 1.5rem;
}

.markdown-editor ol {
    list-style-type: decimal;
    padding-left: 1.5rem;
}

.markdown-editor ul.checklist {
    list-style-type: none;
    padding-left: 0;
}

.markdown-editor ul.checklist li {
    display: flex;
    align-items: center;
}

.markdown-editor ul.checklist li input {
    margin-right: 0.5rem;
}

.markdown-editor li {
    margin-bottom: 0.5rem;
}