
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {

    --color-primary: 82 130 101;
    --bg-color-logo: 82 130 101;
    --font-color: 0 0 0;
    --font-size: '16px';
    --background-image: url("assets/logo/logo-no-background.png");
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Gudea', 'Mukta Mahee', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.shadow-line {
  box-shadow: 0 -2px 10px 0 black !important;
}

a {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
}


label, button, span {
  margin: 0;
  padding: 0;
}


.separate-line {
  @apply mx-6 my-2  bg-gray-1 opacity-20 h-px;
}

.red-border {
  border: 2px solid red;
}

@font-face {
  font-family: 'arg';
  src: local('arg'), url(./assets/fonts/arg.ttf) format('truetype');
}

@font-face {
  font-family: 'Mukta Mahee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/muktamahee.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Gudea';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/gudea.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}