div[tabindex='-1'][role='dialog'] {
  height: 100%;
  max-width: none;
  padding: 0 !important;
}

div[tabindex='-1'][role='dialog'] > div {
  margin: 0 auto;
  height: 100%;
  max-height: none;
  border-radius: 0;
}

/*make scanner in the center of screen*/
div[tabindex='-1'] > div > div > div {
  overflow: hidden;
  height: unset;
}
/*hide info button */
div[tabindex='-1'] > div > div > div > div > img {
  display: none;
}
