div[role="alert"] {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; 
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner img {
  margin: auto;
  width: 10%; 
}


.result-block-container .result-block {
  opacity: 1;
}

@media print {
  button, nav {
    visibility: hidden;  
    display: none;
  }
}